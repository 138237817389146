* {
    font-family: "Ubuntu", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
 }
 :root {
   --blue: #ff3d41;
   --white: #ffffff;
    --gray: #f5f5f5;
    --black1: #222;
    --black2: #999;
  }

  .bg-theame {
    background-color: var(--blue);
    color: var(--white);
  }
  .text-theame {
    color: var(--blue);
  }
  .text-color {
    color: var(--white);
  }
   

